import React, { useState, useEffect } from "react";
import { authHeader } from "../_helpers";
import axios from "axios";
import moment from "moment";
import Sidebar from "./component/Sidebar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { Box, Button, TextField, Typography, Collapse, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { alertActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";
import PageLoaderComp from "../component/pageLoaderComp";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 600,
    borderBottom: '2px solid rgba(224, 224, 224, 1)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ActionButton = styled(Button)({
  textTransform: 'none',
  padding: '4px 12px',
  fontSize: '14px',
  borderRadius: '4px',
  '&.display': {
    color: '#4CAF50',
    border: '1px solid #4CAF50',
  },
  '&.download': {
    color: '#FF9800',
    border: '1px solid #FF9800',
    marginLeft: '8px',
  }
});

export default function Facilities() {
  const currentAdmin = useSelector((state) => state.administrator.admin);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentFiles, setCurrentFiles] = useState([]);
  
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getAllFacilities();
  }, []);

  async function getAllFacilities() {
    setPageLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/admin/facilities`
      );
      setRows(response.data.data);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.error(error);
    }
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">Tableau de bord</Link>,
    <Typography key="2">Gestion des commandes</Typography>,
  ];
  const handleSearch = () => {
    console.log('Searching between', startDate, 'and', endDate);
  };

  const documentSets = {
    //sarl/eurl-sas/sasu
    statutes: {name: 'statutes', title: 'Statutes', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    certificate_deposit_of_funds: {name: 'certificate_deposit_of_funds', title: 'Attestation de dépot des fonds (optionnel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    identity_document_legal_representative: {name: 'identity_document_legal_representative', title: 'Pièce d’identité du représentant légal', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    declaration_no_conviction_legal_representative:{name: 'declaration_no_conviction_legal_representative', title: 'Déclaration de non condamnation du représentant légal', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    vital_card_legal_representative:{name: 'vital_card_legal_representative', title: 'Carte Vital du représentant légal (optionnel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    authorization_setup_legal_representative:{name: 'authorization_setup_legal_representative', title: 'Autorisation d’installation au domicile du représentant légal', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    proof_address_legal_representative:{name: 'proof_address_legal_representative', title: 'Justificatif de domicile', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    domiciliation_contract:{name: 'domiciliation_contract', title: 'contrat de domiciliation', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    bail_commercial:{name: 'bail_commercial', title: 'Bail commercial', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
              
    legal_notice_incorporation:{name: 'legal_notice_incorporation', title: 'Avis de constitution', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    legal_notice_rental_management:{name: 'legal_notice_rental_management', title: 'Avis de location', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    legal_notice_purchase_of_business_assets:{name: 'legal_notice_purchase_of_business_assets', title: 'Annonce légale Achat de fonds de commerce', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    identity_document_of_other_legal_representatives:{name: 'identity_document_of_other_legal_representatives', title: 'Pièce d’identité des autres représentants légaux', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    declaration_no_conviction_other_legal_representative:{name: 'declaration_no_conviction_other_legal_representative', title: ' Déclaration de non-condamnations des autres représentants légaux', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    piece_identite_du_second_representant_legal_ou_associe:{name: 'piece_identite_du_second_representant_legal_ou_associe', title: 'Pièce d’identité du second représentant légal ou associé', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    declaration_non_condamnation_second_representant_legal:{name: 'declaration_non_condamnation_second_representant_legal', title: 'Déclaration de non-condamnation du second représentant légal ou associé', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    //sci

    piece_identite_cogérant:{name: 'piece_identite_cogérant', title: 'Pièce d’identité du co-gérant (optionel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    declaration_non_condamnation_cogerant:{name: 'declaration_non_condamnation_cogerant', title: 'Déclaration de non-condamnation du co-gérant(optionel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    kbis_moins_de_3_mois:{name: 'kbis_moins_de_3_mois', title: 'KBIS de moins de 3 mois (optionel)', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    //dissolution
    pv_de_dissolution:{name: 'pv_de_dissolution', title: 'PV de dissolution', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    pi_du_president:{name: 'pi_du_president', title: 'PI du Président', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    pouvoir:{name: 'pouvoir', title: 'Pouvoir', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    pi_associe:{name: 'pi_associe', title: 'PI Associé', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
  
    dnc_associe:{name: 'dnc_associe', title: 'DNC associé', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    pi_du_tiers:{name: 'pi_du_tiers', title: 'PI du tiers', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},


    dnc_du_tiers:{name: 'dnc_du_tiers', title: 'DNC du tiers', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    justificatif_domicile_tiers:{name: 'justificatif_domicile_tiers', title: 'Justificatif de domicile du tiers', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    justificatif_adresse_liquidateur:{name: 'justificatif_adresse_liquidateur', title: 'Justificatif (facture EDF ou autre) de l’adresse du liquidateur', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},


    pv_de_liquidation:{name: 'pv_de_liquidation', title: 'PV de liquidation', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    bilan_de_cloture:{name: 'bilan_de_cloture', title: 'Bilan de cloture', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    attestation_urssaf:{name: 'attestation_urssaf', title: 'Attestation URSSAF', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    attestation_impot:{name: 'attestation_impot', title: 'Attestation Impôt', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

   // formalities
    pv_agoa:{name: 'pv_agoa', title: 'PV AGOA', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    propositions_affectation_resultat:{name: 'propositions_affectation_resultat', title: 'Propositions d’affectation de résultat', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    bilan:{name: 'bilan', title: 'Bilan', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    compte_de_resultat:{name: 'compte_de_resultat', title: 'Compte de résultat', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    declaration_confidentialité:{name: 'declaration_confidentialité', title: 'Déclaration de confidentialité', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    autre_document:{name: 'autre_document', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    //modification

    pv:{name: 'pv', title: 'PV', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    statuts_maj:{name: 'statuts_maj', title: 'Statuts MAJ', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    piece_identite_representant_legal_actuel:{name: 'piece_identite_representant_legal_actuel', title: 'Pièce d’identité du représentant légal actuel', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    autre_document1:{name: 'autre_document1', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    autre_document2:{name: 'autre_document2', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},


    annonce_legale:{name: 'annonce_legale', title: 'annonce légale', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},


    annonce_legale_depart:{name: 'annonce_legale_depart', title: 'annonce légale départ', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    annonce_legale_arrivee:{name: 'annonce_legale_arrivee', title: 'annonce légale arrivée', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    justificatif_nouveau_siege:{name: 'justificatif_nouveau_siege', title: 'Justificatif nouveau siège', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    liste_des_sieges_anterieurs:{name: 'liste_des_sieges_anterieurs', title: 'Liste des sièges antérieurs ', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    piece_identite_nouveau_dirigeant:{name: 'piece_identite_nouveau_dirigeant', title: 'Pièce d’identité du nouveau dirigeant', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    declaration_non_condamnation_nouveau_dirigeant:{name: 'declaration_non_condamnation_nouveau_dirigeant', title: 'Déclaration de Non condamnation du nouveau dirigeant', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    autre_document3:{name: 'autre_document3', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    autre_document4:{name: 'autre_document4', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},

    autre_document5:{name: 'autre_document5', title: 'Autre document', formats: ['PDF'], url: 'https://certi.legals.fr/download_pdf.php?file=formfiles/Immatriculation/MBE_Societes.pdf'},
    };

  const handleRowClick = (rowId, formfile) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
    if (!formfile) {setCurrentFiles([]);return;}
    try {
      const fileData = typeof formfile === 'string' ? JSON.parse(formfile) : formfile;
      if (Array.isArray(fileData)) {
        const files = fileData.map(item => ({
          title: item.fieldname,
          filename: item.filename,
          originalname: item.originalname,
          mimetype: item.mimetype,
          size: item.size,
          path: item.path,
          status: []
        }));
        setCurrentFiles(files);
      } 
      // If it's an object, convert it to array
      else if (typeof fileData === 'object') {
        const files = Object.entries(fileData).map(([key, value]) => ({
          title: key,
          ...value,
          status: []
        }));
        setCurrentFiles(files);
      }
    } catch (error) {
      console.error("Error processing formfile:", error);
      setCurrentFiles([]);
    }
  };

  const documentsList = currentFiles.length>0?currentFiles : [];

  const handleDownload= (url) => {
    //console.log('documentuploads');
      // Path to the PDF file (in public folder or hosted online)
      const pdfUrl = "https://busyplace.s3.eu-west-3.amazonaws.com/assets/uploads/"+url;
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = pdfUrl;
      link.download = url; // File name for the download
      link.click();
    };
  const handleReceptionClick = async(type, id) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/admin/facilities/send`, 
            {
                type: type,
                id: id,
            },
            { headers: authHeader() }
        );
        if (response.status === 200) {
          dispatch(alertActions.success(response.data.msg));
            console.log('Reception data sent successfully');
        }
    } catch (error) 
    {
        console.error('Error sending reception data:', error);
    }
  };

  const [openDocModal, setOpenDocModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [docMessage, setDocMessage] = useState("");

  const handleDocModalOpen = (rowId) => {
    setSelectedRowId(rowId);
    setOpenDocModal(true);
  };

  const handleDocModalClose = () => {
    setOpenDocModal(false);
    setDocMessage("");
    setSelectedRowId(null);
  };

  const handleDocSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/admin/facilities/send`,
        {
          id: selectedRowId,
          type: 'missingdoc',
          message: docMessage
        },
        { headers: authHeader() }
      );
      if (response.status === 200) {
        dispatch(alertActions.success(response.data.msg));
        console.log('Doc manquant message sent successfully');
        handleDocModalClose();
      }
    } catch (error) {
      console.error('Error sending doc manquant message:', error);
    }
  };

  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}
      {pageLoading ? <PageLoaderComp /> : ""}
      <Dialog open={openDocModal} onClose={handleDocModalClose}>
        <DialogTitle>Doc manquant</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={docMessage}
            onChange={(e) => setDocMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDocModalClose}>Annuler</Button>
          <Button onClick={handleDocSubmit} variant="contained" color="primary">
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
      <div className="w-full h-full bg-white p-8">
        <Typography variant="h4" component="h4" fontWeight={500} color="#1CBE93" mb={3}>
          Gestion des commandes
        </Typography>

        <Stack spacing={2} mb={3}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>

        <div className="flex gap-4 mb-6">
          <TextField
            label="À partir de la de"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            style={{ width: '200px' }}
          />
          <TextField
            label="À la date du"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            style={{ width: '200px' }}
          />
          <Button 
            variant="contained" 
            onClick={handleSearch}
            style={{ 
              backgroundColor: '#1976d2',
              textTransform: 'none',
              minWidth: '120px'
            }}
          >
            Rechercher
          </Button>
        </div>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>N° de Dossier</StyledTableCell>
                <StyledTableCell>Type dossier</StyledTableCell>
                <StyledTableCell>Nom dossier</StyledTableCell>
                <StyledTableCell>Nom de client</StyledTableCell>
                <StyledTableCell>Date de création</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                
                <React.Fragment key={row.id}>
                  <StyledTableRow>
                    <StyledTableCell>{row.id}</StyledTableCell>
                    <StyledTableCell>{
                              row.formtype === 'sarl-eurl' ? 'Création' :
                              row.formtype === 'SAS/SASU' ? 'Création' :
                              row.formtype === 'sci' ? 'Création' :
                              row.formtype === 'modification' ? 'Modification' :
                              row.formtype === 'dissolution' ? 'Dissolution' :
                              row.formtype === 'formalities' ? 'Approbation des comptes' : 'Création'
                            } </StyledTableCell>
                    <StyledTableCell style={{ color: '#0066CC' }}>{row.entreprisename || '-'}</StyledTableCell>
                    <StyledTableCell style={{ color: '#0066CC' }}>{row.firstname+' '+row.lastname}</StyledTableCell>
                    <StyledTableCell>{moment(row.created_at).format('DD MMMM YYYY')}</StyledTableCell>
                    <StyledTableCell>
                      <div 
                        style={{
                          border: '1px solid #4CAF50',
                          color: '#4CAF50',
                          borderRadius: '4px',
                          padding: '6px 12px',
                          display: 'inline-block',
                          fontSize: '14px',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleRowClick(row.id,row.formfile)}
                      >
                        Commande terminée
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={expandedRow === row.id} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2 }}>
                          <Table size="small" aria-label="documents">
                            <TableBody>
                            <TableRow style={{ borderBottom: '1px solid #0066CC'}}>
                                  <TableCell style={{ width: '50%', border: 'none' }}>
                                    <Typography style={{ color: '#0066CC' }}>
                                    Les documents relatifs à votre immatriculation
                                    </Typography>
                                  </TableCell>
                                  <TableCell style={{ border: 'none' }}>
                                    {/* //1976d2 FF9800 */}
                                  <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleReceptionClick('controle', row.id)}
                                        style={{
                                        marginRight: '8px',
                                        textTransform: 'none',
                                        borderColor: '#1976d2',
                                        color: '#1976d2'
                                        }}
                                    >Réception</Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleReceptionClick('send', row.id)}
                                        style={{
                                        marginRight: '8px',
                                        textTransform: 'none',
                                        borderColor: '#FF9800',
                                        color: '#FF9800'
                                        }}
                                    >Envoi</Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleReceptionClick('Validet', row.id)}
                                        style={{
                                        marginRight: '8px',
                                        textTransform: 'none',
                                        borderColor: '#4CAF50',
                                        color: '#4CAF50'
                                        }}
                                    >Validation</Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleDocModalOpen(row.id)}
                                        style={{
                                        marginRight: '8px',
                                        textTransform: 'none',
                                        borderColor: '#1976d2',
                                        color: '#1976d2'
                                        }}
                                    >Doc manquant</Button>
                                  </TableCell>
                                </TableRow>

                                
                              {documentsList.map((doc, index) => (
                                <TableRow key={index}>
                                  <TableCell style={{ width: '50%', border: 'none' }}>
                                    <Typography style={{ color: '#0066CC' }}>
                                      {documentSets[doc.title].title}
                                    </Typography>
                                  </TableCell>
                                  <TableCell style={{ border: 'none' }}>
                                    <ActionButton className="display">
                                        Afficher
                                    </ActionButton>
                                    <ActionButton className="download" onClick={() => handleDownload(doc.filename)}>
                                        Télécharger
                                    </ActionButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}