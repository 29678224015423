/* eslint-disable array-callback-return */
import React, {useState, useEffect } from 'react' 
import moment from 'moment';
import dayjs from 'dayjs';
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select,ListSubheader , TextField ,Divider} from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

export  function ModificationsMultiples(props) {
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <div className='' >
        <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
          <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale : de {props.adsname}</h1>
        </div>
        <div className='py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Choisir l'organe et la date de la décision</h2>
          
          <div className='grid grid-cols-12 items-center mt-2 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-2 sm:mb-0 text-base font-normal text-black-light'>Par décision *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id="type_perdecision_acte" name="type_perdecision_acte"
                  value={props.formData.type_perdecision_acte} onChange={props.handleChange}
                  error={!props.formData.type_perdecision_acte && props.isSubmitted?true:false}
                >
                  <MenuItem value={"AGO"}>de L'Assemblée Générale Ordinaire</MenuItem>
                  <MenuItem value={"AGE"}>de L'Assemblée Générale Extraordinaire</MenuItem>
                  <MenuItem value={"AGM"}>de L'Assemblée Générale Mixte</MenuItem>
                  <MenuItem value={"Gerant"}>du Gérant</MenuItem>
                  <MenuItem value={"President"}>du Président</MenuItem>
                  <MenuItem value={"Conseil_Administration"}>du Conseil d'Administration</MenuItem>
                  <MenuItem value={"Associe_Unique"}>de L'Associé Unique</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de l'AG *</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.date_typeag?
                    <DatePicker value={dayjs(props.formData.date_typeag)}   name="date_typeag" onChange={(e)=>{props.handleChangeDate(e,"date_typeag")}} format={"DD/MM/YYYY"}                   
                    />
                    :
                    <DatePicker  name="date_typeag"  onChange={(e)=>{props.handleChangeDate(e,"date_typeag")}} format={"DD/MM/YYYY"}                   
                    />
                  }
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date d'effet *</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.date_type_effet?
                    <DatePicker value={dayjs(props.formData.date_type_effet)}   name="date_type_effet"   onChange={(e)=>{props.handleChangeDate(e,"date_type_effet")}} format={"DD/MM/YYYY"}                   
                    />
                    :
                    <DatePicker  name="date_type_effet"   onChange={(e)=>{props.handleChangeDate(e,"date_type_effet")}} format={"DD/MM/YYYY"}                   
                    />
                  }
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
        </div>


        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La société</h2>
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7  mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="rcs_societe" 
                value={props.formData.rcs_societe} 
                onChange={props.handleChange} 
              /> 
            </div>
          </div>        
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="denomination_societe" 
                value={props.formData.denomination_societe} 
                onChange={props.handleChange}  
              />
              <StyledTooltip title="Indiquez le nom de la Société tel que mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div> 

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>
              <TextField
                hiddenLabel
                size="small"
                className='w-full'
                id="forme_juridique_societe" 
                name="forme_juridique_societe"
                value={props.formData.forme_juridique_societe} 
                onChange={props.handleChange}  
                error={!props.formData.forme_juridique_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>

              <FormControl size="small" className='w-full'>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  name="capital_societe"
                  value={props.formData.capital_societe} 
                  onChange={props.handleChange}  
                  error={!props.formData.capital_societe  && props.isSubmitted?true:false}
                />
              </FormControl>

              <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>

            </div>
          </div>
          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="address_societe"
                value={props.formData.address_societe} 
                onChange={props.handleChange}  
                error={!props.formData.address_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse suite</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="address_suite_societe"
                value={props.formData.address_suite_societe} 
                onChange={props.handleChange}  
                // error={!props.formData.address_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="code_postal"  maxLength="5"
                value={props.formData.code_postal} 
                onChange={(e)=>{props.handleChange(e)}}  
                error={!props.formData.code_postal  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              {/* <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="ville"
                value={props.formData.ville} 
                onChange={props.handleChange}  
                error={!props.formData.ville  && props.isSubmitted?true:false}
              /> */}
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="ville_id"
                  value={props.formData.ville_id}                   
                  onChange={props.handleChange}  
                  error={!props.formData.ville  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.villeArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="greffe" 
                value={props.formData.greffe} 
                onChange={props.handleChange} 
              />
            </div>
          </div>
        </div>
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Modification de Gérance/Présidence/Direction générale</h2> 
          <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Modification</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez votre choix</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez votre choix"
                  name='modification'
                  value={props.formData.modification} 
                  onChange={props.handleChange}  
                  // error={!props.formData.ville  && props.isSubmitted?true:false}

                >
                  <MenuItem value={"Aucune_modification"}>Aucune modification</MenuItem>
                  <MenuItem value={"partant_sans_remplacement"}>Partant sans remplacement</MenuItem>
                  <MenuItem value={"partant_avec_remplacement"}>Partant avec remplacement</MenuItem>
                  <MenuItem value={"nommination_sans_depart"}>Nomination sans départ</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className={props.formData.modification != "Aucune_modification" ?"grid grid-cols-12 items-start mt-3 sm:mt-6":" grid-cols-12 items-start mt-3 sm:mt-6 hidden"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Fonction</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Choisissez"
                  name="fonction"
                  onChange={props.handleChange}  
                  value={props.formData.fonction} 
                  error={!props.formData.fonction  && props.isSubmitted?true:false}
                > 
                  <ListSubheader>Gérant</ListSubheader>  
                    <MenuItem value="gerant">Gérant</MenuItem>
                    <MenuItem value="gerant_et_associe_indefiniment_et_solidairement_responsable">Gérant et associé indéfiniment et solidairement responsable</MenuItem>
                    <MenuItem value="gerant_non_associe">Gérant non associé</MenuItem>

                  <ListSubheader>Président</ListSubheader> 
                    <MenuItem value="president">Président</MenuItem>
                    <MenuItem value="president_du_directoire">Président du directoire</MenuItem>
                    <MenuItem value="president_du_conseil_dadministration">Président du conseil d'administration</MenuItem>
                    <MenuItem value="president_du_conseil_de_surveillance">Président du conseil de surveillance</MenuItem>

                  <ListSubheader>Directeur général</ListSubheader>                   
                    <MenuItem value="directeur_general">Directeur général</MenuItem>
                    <MenuItem value="directeur_general_dont_la_nomination_a_ete_publiee_au_jo">Directeur général dont la nomination a été publiée au J.O.</MenuItem>
                    <MenuItem value="directeur_general_unique">Directeur général unique</MenuItem>

                  <ListSubheader>Commissaire aux comptes</ListSubheader>                  
                    <MenuItem value="commissaire_aux_comptes">Commissaire aux comptes</MenuItem>
                    <MenuItem value="commissaire_aux_comptes_suppleant">Commissaire aux comptes suppléant</MenuItem>
                    <MenuItem value="commissaire_aux_comptes_titulaire">Commissaire aux comptes titulaire</MenuItem>

                  <ListSubheader>Associé</ListSubheader>
                    <MenuItem value="associe">Associé</MenuItem>
                    <MenuItem value="associe_commandite">Associé commandité</MenuItem>
                    <MenuItem value="associe_en_nom">Associé en nom</MenuItem>
                    <MenuItem value="associe_indefiniment_et_solidairement_responsable">Associé indéfiniment et solidairement responsable</MenuItem>
                    <MenuItem value="associe_indefiniment_responsable">Associé indéfiniment responsable</MenuItem>
                   
                  <ListSubheader>Administrateur</ListSubheader>
                    <MenuItem value={"administrateur"}>Administrateur</MenuItem>                
                    <MenuItem value="administrateur_choisi_en_raison_de_ses_competences">Administrateur choisi en raison de ses compétences</MenuItem>
                    <MenuItem value="administrateur_dont_la_nomination_a_ete_publiee_au_jo">Administrateur dont la nomination a été publiée au J.O.</MenuItem>
                    <MenuItem value="administrateur_nomme_par_le_csa">Administrateur nommé par le CSA</MenuItem>
                    <MenuItem value="administrateur_personnalite_qualifiee">Administrateur personnalité qualifiée</MenuItem>
                    <MenuItem value="administrateur_representant">Administrateur représentant</MenuItem>
                    <MenuItem value="administrateur_representant_des_communes_et_de_leurs_groupements">Administrateur représentant des communes et de leurs groupements</MenuItem>
                    <MenuItem value="administrateur_representant_des_locataires">Administrateur représentant des locataires</MenuItem>
                    <MenuItem value="administrateur_representant_des_usagers">Administrateur représentant des usagers</MenuItem>
                    <MenuItem value="administrateur_representant_du_personnel">Administrateur représentant du personnel</MenuItem>
                    <MenuItem value="administrateur_representant_letat">Administrateur représentant l'Etat</MenuItem>
                    <MenuItem value="administrateur_representant_la_ville_de_paris">Administrateur représentant la ville de Paris</MenuItem>
                    <MenuItem value="administrateur_representant_le_capital_prive">Administrateur représentant le capital privé</MenuItem>
                    <MenuItem value="administrateur_representant_le_capital_public">Administrateur représentant le capital public</MenuItem>
                    <MenuItem value="administrateur_representant_le_parlement">Administrateur représentant le Parlement</MenuItem>
                    <MenuItem value="administrateur_representant_les_collectivites_territoriales">Administrateur représentant les collectivités territoriales</MenuItem>
                    <MenuItem value="administrateur_representant_les_regions">Administrateur représentant les régions</MenuItem>
                    <MenuItem value="administrateur_representant_les_salaries">Administrateur représentant les salariés</MenuItem>
                    <MenuItem value="administrateur_representant_les_salaries_actionnaires">Administrateur représentant les salariés actionnaires</MenuItem>
                    <MenuItem value="administrateur_titulaire">Administrateur titulaire</MenuItem>
                  
                  
                  <ListSubheader>Membre</ListSubheader>                  
                    <MenuItem value="membre_du_conseil_de_surveillance">Membre du conseil de surveillance</MenuItem>
                    <MenuItem value="membre_du_directoire">Membre du directoire</MenuItem>
                  
                  <ListSubheader>Vice-président</ListSubheader>                   
                    <MenuItem value="vice_president_du_conseil_dadministration">Vice-président du conseil d'administration</MenuItem>
                    <MenuItem value="vice_president_du_conseil_de_surveillance">Vice-président du conseil de surveillance</MenuItem>
                    <MenuItem value="vice_president_du_directoire">Vice-président du directoire</MenuItem>
                  
                </Select>
              </FormControl>
            </div>
          </div>

          <div  className={(props.formData.modification ==="partant_sans_remplacement"|| props.formData.modification ==="partant_avec_remplacement")?"grid grid-cols-12 items-start mt-3 sm:mt-6":" grid-cols-12 items-start mt-3 sm:mt-6 hidden"}>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Cause</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Choisissez"
                  name="cause"
                  value={props.formData.cause}
                  onChange={props.handleChange}  
                  error={!props.formData.cause  && props.isSubmitted?true:false}
                >
                  <MenuItem value={"demission"}>Démission</MenuItem>
                  <MenuItem value={"revocation"}>Révocation</MenuItem>
                  <MenuItem value={"demission_pour_ordre"}>Démission pour ordre</MenuItem>
                  <MenuItem value={"deces"}>Décés</MenuItem>
                  <MenuItem value={"fin_mandat"}>Fin de mandat</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

      <div className={(props.formData.modification ==="partant_sans_remplacement" || props.formData.modification ==="partant_avec_remplacement")?"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6":"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6 hidden"}>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Partant</h2>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Choisissez"
                name="type_personnepartant"
                value={props.formData.type_personnepartant}
                onChange={props.handleChange}  
                error={!props.formData.type_personnepartant  && props.isSubmitted?true:false}
              >
                <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={props.formData.type_personnepartant?"":"hidden"}>
          <div className={props.formData.type_personnepartant ==="PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"}>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="denominationpartant"
                value={props.formData.denominationpartant}
                onChange={props.handleChange}  
                error={!props.formData.denominationpartant  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Choisissez"
                  name="representation_civilitepartant"
                  value={props.formData.representation_civilitepartant}
                  onChange={props.handleChange}  
                  // error={!props.formData.representation_civilitepartant  && props.isSubmitted?true:false}
                >
                  <MenuItem value={"Monsieur"}>Monsieur</MenuItem>
                  <MenuItem value={"Madame"}>Madame</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="representation_nompartant"
                value={props.formData.representation_nompartant}
                onChange={props.handleChange}  
                error={!props.formData.representation_nompartant  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="representation_prenompartant"
                value={props.formData.representation_prenompartant}
                onChange={props.handleChange}  
                error={!props.formData.representation_prenompartant  && props.isSubmitted?true:false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={props.formData.modification ==="nommination_sans_depart" || props.formData.modification ==="partant_avec_remplacement" ?"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6":"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6 hidden"}>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Arrivant</h2>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez votre choix</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez votre choix"
                name="type_personnearrivant"
                value={props.formData.type_personnearrivant}
                onChange={props.handleChange}  
                error={!props.formData.type_personnearrivant  && props.isSubmitted?true:false}
              >
                <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div  className={props.formData.type_personnearrivant === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"}>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="rcsarrivant"
              value={props.formData.rcsarrivant}
              onChange={props.handleChange}  
              error={!props.formData.rcsarrivant  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className={props.formData.type_personnearrivant === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"}>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="denominationarrivant"
              value={props.formData.denominationarrivant}
              onChange={props.handleChange}  
              error={!props.formData.denominationarrivant  && props.isSubmitted?true:false}
            />
          </div>
        </div>

        <div className={props.formData.type_personnearrivant === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"} >
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez"
                name="forme_juridiquearrivant"
                onChange={props.handleChange}  
                value={props.formData.forme_juridiquearrivant}
                error={!props.formData.forme_juridiquearrivant  && props.isSubmitted?true:false}
              >
                <MenuItem value={10}>Non</MenuItem>
                <MenuItem value="">Choisir la forme juridique</MenuItem>
                <MenuItem value="SAS">Société par actions simplifiée (SAS)</MenuItem>
                <MenuItem value="SAS_CAPITAL_VARIABLE">Société par actions simplifiée à capital variable</MenuItem>
                <MenuItem value="SASU">Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                <MenuItem value="SASU_CAPITAL_VARIABLE">Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                <MenuItem value="SARL">Société à responsabilité limitée (SARL)</MenuItem>
                <MenuItem value="SARL_CAPITAL_VARIABLE">Société à responsabilité limitée à capital variable</MenuItem>
                <MenuItem value="SARLU">Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                <MenuItem value="SARLU_CAPITAL_VARIABLE">Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                <MenuItem value="SCI">Société civile immobilière (SCI)</MenuItem>
                <MenuItem value="SCI_CAPITAL_VARIABLE">Société civile immobilière à capital variable</MenuItem>
                <MenuItem value="SC">Société civile</MenuItem>
                <MenuItem value="SCA">Société en commandite par actions (SCA)</MenuItem>
                <MenuItem value="SA">Société ànonyme (SA)</MenuItem>
                <MenuItem value="SA_CAPITAL_VARIABLE">Société ànonyme à capital variable</MenuItem>
                <MenuItem value="SNC">Société en nom collectif (SNC)</MenuItem>
                <MenuItem value="SNC_CAPITAL_VARIABLE">Société en nom collectif à capital variable</MenuItem>
                <MenuItem value="SCM">Société civile de moyens (SCM)</MenuItem>
                <MenuItem value="SCP">Société civile professionnelle (SCP)</MenuItem>
                <MenuItem value="SCPI">Société civile de placement immobilier (SCPI)</MenuItem>
                <MenuItem value="SCCV">Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                <MenuItem value="SELAFA">Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                <MenuItem value="SELAS">Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                <MenuItem value="SELCA">Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                <MenuItem value="SELARL">Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                <MenuItem value="SELEURL">Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                <MenuItem value="SELASU">Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                <MenuItem value="SCEA">Société civile d'exploitation agricole (SCEA)</MenuItem>
                <MenuItem value="EARL">Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                <MenuItem value="GAEC">Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                <MenuItem value="GFA">Groupement foncier agricole (GFA)</MenuItem>
                <MenuItem value="EIRL">Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                <MenuItem value="SPFPLAS">Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className={props.formData.type_personnearrivant === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"}>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="capitalarrivant"
              value={props.formData.capitalarrivant}
              onChange={props.handleChange}  
              error={!props.formData.capitalarrivant  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className={props.formData.type_personnearrivant === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"}>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Choisissez"
                name="civilite_physiquearrivant"
                value={props.formData.civilite_physiquearrivant}
                onChange={props.handleChange}  
                error={!props.formData.civilite_physiquearrivant  && props.isSubmitted?true:false}
              >
                <MenuItem value={"Monsieur"}>Monsieur</MenuItem>
                <MenuItem value={"Madame"}>Madame</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className={props.formData.type_personnearrivant === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"} >
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="nom_physiquearrivant"
              value={props.formData.nom_physiquearrivant}
              onChange={props.handleChange}  
              error={!props.formData.nom_physiquearrivant  && props.isSubmitted?true:false}
            />
          </div>
        </div>

        <div className={props.formData.type_personnearrivant === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"} >
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="prenom_physiquearrivant"
              value={props.formData.prenom_physiquearrivant}
              onChange={props.handleChange}  
              error={!props.formData.prenom_physiquearrivant  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        {
        props.formData.type_personnearrivant?
        <>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="adressearrivant"
                value={props.formData.adressearrivant}
                onChange={props.handleChange}  
                error={!props.formData.adressearrivant  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse suite</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="adresse_suitearrivant"
                value={props.formData.adresse_suitearrivant}
                onChange={props.handleChange}  
                // error={!props.formData.adresse_suitearrivant  && props.isSubmitted?true:false}
                
              />
            </div>
          </div>
        </>
        :""}
        <div className={props.formData.type_personnearrivant === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"}>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l'étranger?</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez"
                name="resident_etrangerarrivant"
                value={props.formData.resident_etrangerarrivant}
                onChange={props.handleChange}  
                // error={!props.formData.resident_etrangerarrivant  && props.isSubmitted?true:false}
                
              >
                <MenuItem value={"Non"}>Non</MenuItem>
                <MenuItem value={"Oui"}>Oui</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {
        props.formData.type_personnearrivant?
        <>
        <div className={(props.formData.type_personnearrivant === "PERSONNE_PHYSIQUE" && props.formData.resident_etrangerarrivant=="Non")|| props.formData.type_personnearrivant === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"} >
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="code_postalarrivant"
              value={props.formData.code_postalarrivant}
              onChange={(e)=>{props.handleChange(e)}} 
              error={!props.formData.code_postalarrivant  && props.isSubmitted?true:false}
            />
          </div>
        </div>

        <div className='grid grid-cols-12 items-start mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className={((props.formData.type_personnearrivant === "PERSONNE_PHYSIQUE"&& props.formData.resident_etrangerarrivant==="Oui"  ))?"w-full":"hidden w-full"}
                name="villearrivant"
                value={props.formData.villearrivant}
                onChange={props.handleChange}  
                error={!props.formData.villearrivant  && props.isSubmitted?true:false}
              />
              <FormControl size="small" className={((props.formData.type_personnearrivant === "PERSONNE_PHYSIQUE"&& props.formData.resident_etrangerarrivant==="Oui"  ))?"hidden w-full":" w-full"} >
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="villearrivant_id"
                  value={props.formData.villearrivant_id}
                  onChange={props.handleChange}  
                  error={!props.formData.villearrivant  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.villearrivantArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
          </div>
        </div>
        </>
        :""}
        <div className={props.formData.type_personnearrivant === "PERSONNE_PHYSIQUE" && props.formData.resident_etrangerarrivant=="Oui"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"} >
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="paysarrivant"
              onChange={props.handleChange} 
              value={props.formData.paysarrivant} 
              error={!props.formData.paysarrivant  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className={props.formData.type_personnearrivant === "PERSONNE_MORALE" ?"grid grid-cols-12 mt-6 items-center":" grid-cols-12 mt-6 items-center hidden"}>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe / RCS</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="greffearrivant"
              onChange={props.handleChange} 
              value={props.formData.greffearrivant} 
              error={!props.formData.greffearrivant  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        {props.formData.type_personnearrivant === "PERSONNE_MORALE"?
        <>
          <p className='text-base font-bold text-secondary  mt-6'>Représentée par</p>
          <div className='grid grid-cols-12 items-start mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Choisissez"
                  name="representation_civilitearrivant"
                  onChange={props.handleChange}  
                  value={props.formData.representation_civilitearrivant}
                  // error={!props.formData.representation_civilitearrivant  && props.isSubmitted?true:false}
                >
                  <MenuItem value={"Monsieur"}>Monsieur</MenuItem>
                  <MenuItem value={"Madame"}>Madame</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="representation_nomarrivant"
                value={props.formData.representation_nomarrivant}
                onChange={props.handleChange}  
                error={!props.formData.representation_nomarrivant  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="representation_prenomarrivant"
                value={props.formData.representation_prenomarrivant}
                onChange={props.handleChange}  
                error={!props.formData.representation_prenomarrivant  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          </>
        :""}
      </div> 
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Transfert de siège social</h2>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Modification</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez votre choix</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez votre choix"               
                name="type_acte_transfer_siege"
                value={props.formData.type_acte_transfer_siege}
                onChange={props.handleChange}  
                
              >
                <MenuItem value={"Aucune_modification"}>Aucune modification</MenuItem>
                {/* <MenuItem value={"Transfert_siege_social"}>Transfert de siège social (départ + arrivée)</MenuItem> */}
                <MenuItem value={"Transfert_siege_ressort_depart"}>Transfert de siège même ressort</MenuItem>
                <MenuItem value={"Transfert_siege_Mixte"}>Transfert de siège hors ressort (arrivée)</MenuItem>

              </Select>
            </FormControl>

          </div>
        </div>
      </div>
      <div className={props.formData.type_acte_transfer_siege ==="Transfert_siege_ressort_depart"|| props.formData.type_acte_transfer_siege ==="Transfert_siege_Mixte"?"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6":"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6 hidden"} >
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Nouvelle adresse du siège social</h2>
        <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="adresse_transfer"
              value={props.formData.adresse_transfer}
              onChange={props.handleChange}  
              error={!props.formData.adresse_transfer  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse suite</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="adresse_suite_transfer"
              value={props.formData.adresse_suite_transfer}
              onChange={props.handleChange}  
              // error={!props.formData.adresse_transfer  && props.isSubmitted?true:false}
            />
          </div>
        </div>


        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="code_postal_transfer"
              value={props.formData.code_postal_transfer}
              onChange={(e)=>{props.handleChange(e)}}
              error={!props.formData.code_postal_transfer  && props.isSubmitted?true:false}
            />
          </div>
        </div>


        <div className='grid grid-cols-12 items-start mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            {/* <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="ville_transfer"
              value={props.formData.ville_transfer}
              onChange={props.handleChange}  
              error={!props.formData.ville_transfer  && props.isSubmitted?true:false}
            /> */}
            <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="ville_transfer_id"
                  value={props.formData.ville_transfer_id}
                  onChange={props.handleChange}  
                  error={!props.formData.ville_transfer  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.ville_transferArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
          </div>
        </div>

        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="greffe_transfer"
              value={props.formData.greffe_transfer}
              onChange={props.handleChange}  
              error={!props.formData.greffe_transfer  && props.isSubmitted?true:false}
            />
          </div>
        </div>
      </div>
      <div className={props.formData.type_acte_transfer_siege ==="Transfert_siege_Mixte"?"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6":"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6 hidden"} >
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La direction</h2>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Fonction *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez votre choix"
                name="resident_etranger_gerancefun"
                onChange={props.handleChange}  
                value={props.formData.resident_etranger_gerancefun}
                error={!props.formData.resident_etranger_gerancefun  && props.isSubmitted?true:false}
              >
                <MenuItem value={"Gerant"}>Gérant</MenuItem>
                <MenuItem value={"President"}>Président</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Choisissez"
                name="la_grance_civile"
                onChange={props.handleChange}  
                value={props.formData.la_grance_civile}
                error={!props.formData.la_grance_civile  && props.isSubmitted?true:false}
              >
                <MenuItem value={"Monsieur"}>Monsieur</MenuItem>
                <MenuItem value={"Madame"}>Madame</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="nom_la_grance"
              onChange={props.handleChange}  
              value={props.formData.nom_la_grance}
              error={!props.formData.nom_la_grance  && props.isSubmitted?true:false}
            />
          </div>
        </div>

        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="prenom_la_grance"
              value={props.formData.prenom_la_grance}
              onChange={props.handleChange}  
              error={!props.formData.prenom_la_grance  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="adresse_la_grance"
              value={props.formData.adresse_la_grance}
              onChange={props.handleChange}  
              error={!props.formData.adresse_la_grance  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse suite</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="adresse_suite_la_grance"
              value={props.formData.adresse_suite_la_grance}
              onChange={props.handleChange}  
              // error={!props.formData.adresse_suite_la_grance  && props.isSubmitted?true:false}
              
            />
          </div>
        </div>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l'étranger?</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez"
                name="resident_etranger_la_grance"
                value={props.formData.resident_etranger_la_grance}
                onChange={props.handleChange}  
                // error={!props.formData.resident_etranger_la_grance  && props.isSubmitted?true:false}
                
              >
                <MenuItem value={"Non"}>Non</MenuItem>
                <MenuItem value={"Oui"}>Oui</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={props.formData.resident_etranger_la_grance=="Non"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"} >
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="code_postal_la_grance"
              value={props.formData.code_postal_la_grance}
              onChange={(e)=>{props.handleChange(e)}}
              error={!props.formData.code_postal_la_grance  && props.isSubmitted?true:false}
            />
          </div>
        </div>

        <div className='grid grid-cols-12 items-start mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville  *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className={props.formData.resident_etranger_la_grance==="Oui"?"w-full":"hidden w-full"}
                name="ville_la_grance"
                 value={props.formData.ville_la_grance}
                onChange={props.handleChange}  
                error={!props.formData.ville_la_grance  && props.isSubmitted?true:false}
              />
               <FormControl size="small" className={props.formData.resident_etranger_la_grance==="Oui"?"hidden w-full":" w-full"} >
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="ville_la_grance_id"
                  value={props.formData.ville_la_grance_id}
                  onChange={props.handleChange}  
                  error={!props.formData.ville_la_grance  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.ville_la_granceArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
          </div>
        </div>
        <div className={props.formData.resident_etranger_la_grance==="Oui"?"grid grid-cols-12 mt-6 items-center":"hidden  grid-cols-12 mt-6 items-center"} >
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="pays_la_grance"
              value={props.formData.pays_la_grance}
              onChange={props.handleChange}  
              error={!props.formData.pays_la_grance  && props.isSubmitted?true:false}
            />
          </div>
        </div>
      </div>
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Modification de dénomination sociale</h2>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Modification</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez votre choix</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez votre choix"
                name="type_denomination_sociale"
                value={props.formData.type_denomination_sociale}
                onChange={props.handleChange}  
                // error={!props.formData.type_denomination_sociale  && props.isSubmitted?true:false}
              >
                <MenuItem value={"Aucune_modification"}>Aucune modification</MenuItem>
                <MenuItem value={"NOUVEL_DENOMINATION"}>Modification de la dénomination sociale</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>   
      <div className={props.formData.type_denomination_sociale=== "NOUVEL_DENOMINATION"?"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6":"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6 hidden"}>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Nouvelle dénomination</h2>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="Denomination_changename"
              value={props.formData.Denomination_changename}
              onChange={props.handleChange}  
              error={!props.formData.Denomination_changename  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ancien sigle </label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="Ancien_sigle_changename"
              value={props.formData.Ancien_sigle_changename}
              onChange={props.handleChange}  
              // error={!props.formData.Ancien_sigle_changename  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nouveau sigle </label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="Nouveau_sigle_changename"
              value={props.formData.Nouveau_sigle_changename}
              onChange={props.handleChange}  
              // error={!props.formData.Nouveau_sigle_changename  && props.isSubmitted?true:false}
            />
          </div>
        </div>
      </div>         
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Modification de l'objet social</h2>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Modification</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez votre choix</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez votre choix"
                name="type_modification_objete_social"
                onChange={props.handleChange}  
                value={props.formData.type_modification_objete_social}
                // error={!props.formData.type_modification_objete_social  && props.isSubmitted?true:false}
              >
                <MenuItem value={"Aucune_modification"}>Aucune modification</MenuItem>
                <MenuItem value={"modification_sige_objete"}>Modification de l'objet social</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>    
      <div className={props.formData.type_modification_objete_social=== "modification_sige_objete"?"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6":"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6 hidden"}>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Nouvel objet social</h2>

        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Type *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez votre choix"
                name="type_new_objet"
                value={props.formData.type_new_objet}
                onChange={props.handleChange}  
                error={!props.formData.type_new_objet  && props.isSubmitted?true:false}
              >
                <MenuItem value={"Extension"}>Extension</MenuItem>
                <MenuItem value={"Changement"}>Changement</MenuItem>
                <MenuItem value={"Suppression"}>Suppression</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Objet *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              className='w-full'
              multiline
              rows={4}
              name="novel_objet"
              value={props.formData.novel_objet}
              onChange={props.handleChange}  
              error={!props.formData.novel_objet  && props.isSubmitted?true:false}
            />
          </div>
        </div>
      </div> 
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Changement de durée</h2>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Modification</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez votre choix</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez votre choix"
                name="type_changement_duree"
                onChange={props.handleChange}  
                value={props.formData.type_changement_duree}
                // error={!props.formData.type_changement_duree  && props.isSubmitted?true:false}
              >
                <MenuItem value={"non_sige_deliquidation"}>Aucune modification</MenuItem>
                <MenuItem value={"oui_sige_deliquidation"}>Modification de la durée de la société</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div> 
      <div className={props.formData.type_changement_duree=== "oui_sige_deliquidation"?"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6":"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6 hidden"} >
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Durée</h2>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              placeholder='ans'
              name="changement_Duree"
              value={props.formData.changement_Duree}
              onChange={props.handleChange}  
              error={!props.formData.changement_Duree  && props.isSubmitted?true:false}
            />
          </div>
        </div>
      </div>
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Modification du capital</h2>
        <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Modification</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez votre choix</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sélectionnez votre choix"
                name="type_modification_capital"
                onChange={props.handleChange}  
                value={props.formData.type_modification_capital}
                // error={!props.formData.type_modification_capital  && props.isSubmitted?true:false}
              >
                <MenuItem value={"aucune_modification"}>Aucune modification</MenuItem>
                <MenuItem value={"modification_capital"}>Modification de capital</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className={props.formData.type_modification_capital=== "modification_capital"?"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6":"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6 hidden"} >
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Nouveau capital</h2>
        <div className='grid grid-cols-12 items-center mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Montant *</label>
          {/* <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'> */}
          <div className='col-span-12 sm:col-span-9 md:col-span-4  inline-flex items-center gap-2'>

            <FormControl size="small" className='w-full'>
              <OutlinedInput
                id="outlined-adornment-weight"
                endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                name="Montant_capital"
                onChange={props.handleChange}  
                value={props.formData.Montant_capital}
                error={!props.formData.Montant_capital  && props.isSubmitted?true:false}
              />
            </FormControl>
          </div>
        </div>

        <div  className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nature de l'apport *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Choisir la nature</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Choisir la nature"
                name="nature_apport"
                onChange={props.handleChange}  
                value={props.formData.nature_apport}
                error={!props.formData.nature_apport  && props.isSubmitted?true:false}
              >
                <MenuItem value="En numéraire"> En numéraire</MenuItem>
                <MenuItem value="De compte courant">De compte courant</MenuItem>
                <MenuItem value="De compte courant dassociés">De compte courant d'associés</MenuItem>
                <MenuItem value="De biens immobiliers">De biens immobiliers</MenuItem>
                <MenuItem value="De fonds de commerce">De fonds de commerce</MenuItem>
                <MenuItem value="De titres"> De titres</MenuItem>
                <MenuItem value="En incorporation de réserve"> En incorporation de réserve</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>            
    </div>
    </>
  )
}
